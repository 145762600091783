import payment from "../payment.vue";
import adminConfirmDialog from "@/components/organisms/VDialog/adminConfirmDialog.vue";
export default {
  components: {
    payment,
    adminConfirmDialog,
  },

  data() {
    return {
      menu: [
        {
          name: "통계",
          value: "total",
        },
        {
          name: "유저 관리",
          value: "user",
        },
        {
          name: "오더 관리",
          value: "order",
        },
        {
          name: "결제 관리",
          value: "payment",
        },
        {
          name: "회사 관리",
          value: "menu",
        },
        {
          name: "설정",
          value: "setting",
        },
      ],
      selectedMenu: null,
    };
  },

  methods: {
    selectMenu(item) {
      this.selectedMenu = item;
    },
  },
  async created() {
    this.$store.dispatch("CHECK_ADMIN");
  },
};
